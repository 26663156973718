import { polyfill } from 'es6-promise'
polyfill()

import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
// Transpiler for older browsers - fix for IE11
import 'core-js/stable';

Vue.use(Vuex)

export default new Vuex.Store({});