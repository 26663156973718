var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-to-pdf",attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('button',{staticClass:"content-to-pdf__button",domProps:{"textContent":_vm._s(_vm.downloadButtonText)},on:{"click":function($event){return _vm.generatePDF()}}}),_vm._v(" "),_c('vue-html2pdf',_vm._b({ref:"html2Pdf",on:{"progress":function($event){return _vm.onProgress($event)},"beforeDownload":function($event){return _vm.beforeDownload()},"hasDownloaded":function($event){return _vm.hasDownloaded($event)}},scopedSlots:_vm._u([{key:"pdf-content",fn:function(){return [_c('h3',{staticClass:"content-to-pdf__timestamp"},[_vm._v("PDF Generated On: "+_vm._s(_vm.timestamp))]),_vm._v(" "),_vm._t("default")]},proxy:true}],null,true)},'vue-html2pdf',{
    floatLayout: true,
    enableDownload: true,
    previewModal: true,
    manualPagination: true,
    htmlToPdfOptions: _vm.htmlToPdfOptions,
    pdfContentWidth: '100%',
  },false)),_vm._v(" "),(_vm.progress > 0)?_c('div',{staticClass:"content-to-pdf__progress"},[_c('div',{staticClass:"content-to-pdf__progress-bar",style:({ width: `${_vm.progress}%` })})]):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.showLoader)?_c('div',{staticClass:"loader"},[_c('div',{staticClass:"loader__wheel"}),_vm._v(" "),_c('div',{staticClass:"loader__text"})]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }