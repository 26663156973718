<template>
  <div @mouseover="onHover" @mouseleave="onLeave" class="dropdown">
    <slot name="header" class=""/>
    <section v-if="display" class="dropdown__menu">
      <div class="dropdown__buffer" />
      <div class="dropdown__slot">
        <slot name="dropdown" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "drop-down",

  data () {
    return {
      display: false
    }
  },

  methods: {
    onHover () {
      this.display = true
    },

    onLeave () {
      this.display = false
    },

    onClick () {
      this.display = !this.display
    }
  }

};
</script>
