<template>
  <button 
    class="scroll-to-top"
    @click="scrollToTop"
  >
    <slot />
  </button>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

export default {
  name: 'ScrollToTop',

  computed: {
    supportsNativeSmoothScroll() {
      return 'scrollBehavior' in document.documentElement.style
    }
  },

  methods: {
    scrollToTop () {
      this.animate(0)
    },

    animate (yCoord) {
      if(this.supportsNativeSmoothScroll) {
        window.scrollTo({ top: yCoord, behavior: 'smooth' })
      } else {
        window.scroll({ top: yCoord, left: 0, behavior: 'smooth' })
      }
    }
  }
}
</script>