// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Vue from 'vue/dist/vue.esm'
import Turbolinks from "turbolinks"
import TurbolinksAdapter from 'vue-turbolinks'

import store from '../store.js'

//components
import NavBurger from '~/components/nav/NavBurger'
import FilterableTable from '@unep-wcmc/wcmc-components'
import ScrollToTop from '~/components/scroll-to-top/ScrollToTop'
import ContentToPdf from '~/components/pdf/ContentToPdf'

Vue.use(TurbolinksAdapter)
Vue.use(FilterableTable, { store })

Turbolinks.start()

document.addEventListener('turbolinks:load', () => {
  if(document.getElementById('v-app')) {
    new Vue({
      el: '#v-app',
      store,
      components: {
        ContentToPdf,
        NavBurger,
        ScrollToTop
      }
    })
  }
})
