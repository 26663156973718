<template>
  <div class="content-to-pdf" slot="pdf-content">
    <button @click="generatePDF()" class="content-to-pdf__button" v-text="downloadButtonText" />
    <vue-html2pdf v-bind="{
      floatLayout: true,
      enableDownload: true,
      previewModal: true,
      manualPagination: true,
      htmlToPdfOptions,
      pdfContentWidth: '100%',
    }" @progress="onProgress($event)" @beforeDownload="beforeDownload()" @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf">
      <template class="content-to-pdf__box" v-slot:pdf-content>
        <h3 class="content-to-pdf__timestamp">PDF Generated On: {{ timestamp }}</h3>
        <slot />
      </template>
    </vue-html2pdf>
    <div class="content-to-pdf__progress" v-if="progress > 0">
      <div class="content-to-pdf__progress-bar" :style="{ width: `${progress}%` }" />
    </div>
    <slot />
    <div v-if="showLoader" class="loader">
      <div class="loader__wheel"></div>
      <div class="loader__text"></div>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    VueHtml2pdf,
  },

  props: {
    downloadButtonText: {
      type: String,
      default: "Download as pdf",
    },
  },
  data() {
    return {
      progress: 0,
      showLoader: false,
      timestamp: this.getTimestamp(),
      timestampInterval: null
    }
  },
  computed: {
    htmlToPdfOptions() {
      return {
        margin: [15, 0],
        jsPDF: {
          format: 'a3',
          orientation: 'portrait',
        },
        pagebreak: {
          mode: ['avoid-all', 'css', 'legacy'],
          avoid: ['content-to-pdf__sub-block', '.content-to-pdf__block'] // To make sure it does cut off first by a sub-block and then block so it does not cut off in the middle of a paragragh
        },
        html2canvas: {
          useCORS: true,
          y: 0,
          x: 0,
          scrollY: 0,
          scrollX: 0,
          // quality: 4, // reduce here if people complaning file size too big
          scale: 2 // reduce here if people complaning file size too big but it will be blurry
        },
        image: {
          type: 'jpeg',
          quality: 2 // reduce here if people complaning file size too big but it will be blurry
        },
        filename: `${window.location.pathname.split("/").pop()}.pdf`
      }
    },
  },
  watch: {
    progress(value, oldValue) {
      if (value !== oldValue) {
        this.progress = value;
      }

      if (value === 100) {
        this.progress = 0;
      }
    },
  },
  methods: {
    generatePDF() {
      try {
        this.$refs.html2Pdf.generatePdf();
      } catch (error) {
        this.showLoader = false;
      }
    },
    onProgress(event) {
      this.progress = event
    },
    beforeDownload() {
      this.showLoader = true
    },
    hasDownloaded() {
      this.showLoader = false;
    },
    addLineBreaks() {
      const elements = document.querySelectorAll('.content-to-pdf__box *');
      // if the document is higher than 4961px, add a page break every 4961px
      //https://stackoverflow.com/questions/60130118/is-there-a-way-to-use-an-if-statement-and-see-if-a-divs-height-is-larger-or-sm

      elements.forEach((element) => {
        element.innerHTML = element.innerHTML.replace(/(\r\n|\n|\r)/gm, "<br />");
      });
    },
    getTimestamp() {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      return today.toString()
    },
    setTimestampInterval() {
      const _this = this
      return setInterval(function () {
        _this.timestamp = _this.getTimestamp()
      }, 1000)
    }
  },
  beforeMount() {
    this.timestampInterval = this.setTimestampInterval()
  },
  beforeDestroy() {
    clearInterval(this.timestampInterval)
  }
};
</script>