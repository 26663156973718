<template>
  <a 
    :id="link.id" 
    role="menuitem" 
    :class="[{'is-current-page': link.is_current_page}, 'nav__a']" 
    :href="link.url" 
  >
    {{link.title}}
  </a>
</template>

<script>
export default {
  name: 'nav-link',

  props: {
    link: { //{ id: String, title: String, url: String }
      required: true,
      type: Object
    }
  }
}
</script>
