<template>
  <div class="nav">
    <div
      :class="['nav__pane', {'active': isNavPaneOpen}]"
    >

      <button
        class="nav__button--close"
        @click="closeNavPane"
      >
        <svg-cross />
      </button>

      <div class="nav__flex">
        <nav-link :link="links[0]" class="nav__ul"/>
        <drop-down class="nav__dropdown">
          <template v-slot:header>
            <div class="nav__ul" :class="{ 'nav__dropdown--selected': isOtherIndicatorsPage }">
              Other indicators
            </div>
          </template>
          <template v-slot:dropdown class="">
            <div v-for="(link, indicIndex) in linksPages.indicators" :key="indicIndex">
                <nav-link :link="link"/>
            </div>
          </template>
        </drop-down>
        <ul class="nav__ul v-nav-pane-target">
          <li v-for="link in linksPages.otherPages" :key="link.id" class="nav__li">
            <nav-link :link="link"/>
          </li>
          <li class="nav__li">
            <a v-if="user !== null" :href="logOut.url" class="nav__a">{{ logOut.text }}</a>
          </li>
        </ul>
      </div>
    </div>

    <button
      :class="['nav__button--burger', {'active': !isNavPaneOpen}]"
      @click="openNavPane"
    >
      <svg-burger />
    </button>
  </div>
</template>

<script>
import NavLink from "./NavLink"
import mixinResponsive from "../../mixins/mixin-responsive"
import SvgBurger from "../svg/SvgBurger"
import SvgCross from "../svg/SvgCross"
import DropDown from "./DropDown.vue"

export default {
  name: 'nav-burger',

  components: {
    NavLink,
    SvgBurger,
    SvgCross,
    DropDown
  },

  mixins: [
    mixinResponsive
  ],

  props: {
    links: { //[ { id: String, title: String, url: String } ]
      required: true,
      type: Array
    },

    user: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {
      isNavPaneOpen: false
    }
  },

  computed: {
    linksPages () {
      const indicators = this.links.filter(link => link.id === 'component-indicators' || link.id === 'complementary-indicators')
      const otherPages = this.links.filter(link => link.id !== 'component-indicators').filter(link => link.id !== 'complementary-indicators')
      const otherPagesWithoutHeadlineIndicators = otherPages.filter(link => link.id !== 'index')
      return {
        indicators: indicators,
        otherPages: otherPagesWithoutHeadlineIndicators
      }
    },

    isOtherIndicatorsPage () {
      return (window.location.href.includes('component-indicators') || window.location.href.includes('complementary-indicators'))
    },

    logOut () {
      return { text: "Log out", url: `${window.location.origin}/users/sign_out` }
    }
  },

  methods: {
    openNavPane () {
      this.isNavPaneOpen = true
    },

    closeNavPane () {
      this.isNavPaneOpen = false
    }
  }
}
</script>
